.joinWaitlist {
  padding: 4px 2px;
  border-radius: 8px;
  width: 172px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.joinWaitlist button {
  padding: 15px 18px;
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  width: 100%;
  font-size: 18px;
  background: #58c90d;
  box-shadow: inset 0px -6px 4px rgba(10, 10, 10, 0.25),
    inset 0px 4px 16px #2f2f2f;
  border-radius: 8px;
  border: 1px solid #000;
  cursor: pointer;
}
